#twitter-widget-0 { 
    width: 600px !important;
    min-height: 100% !important;
    margin: 1px;
    z-index: 10;
}

div[data-testid="timeline-footer"] {
    display:none !important;
}

.css-1dbjc4n>div:nth-last-child() {
    background-color:blue !important;
    border: 1px solid red !important;
    display:none !important;
}