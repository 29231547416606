html,
body {
    height: 100%;
    margin: 0;
}

/* -------- MAIN PAGE CONTAINER : (3360px x 2004px) -------- */
/* ------------------------- WITH ------------------------- */
/* ----------- MAIN SIDEBAR : (230px x 2004px) ----------- */
/* ------- 4 MAIN CONTAINERS : (1565px x 1002px) -------- */
.main-container-laplace {
    display: grid;
    grid-template-columns: 230px 1565px 1565px;
    grid-template-rows: 1002px 1002px;
    grid-auto-flow: row;
    grid-template-areas:
        "sidebar main-half-container-top"
        "sidebar main-half-container-bottom";
    width: 3360px;
    height: 2004px;
}
.main-container-laplace * div {
    overflow: hidden;
}
.main-container-laplace * {
    position: relative;
}

/* ---- MAIN HALF CONTAINER - TOP & BOTTOM : (3130px x 1002px) ---- */
.main-half-container-top {
    grid-area: 1 / 2 / 2 / 4;
    display: grid;
    grid-template-columns: 1565px 1565px;
    grid-template-rows: 1002px;
    grid-auto-flow: row;
    gap: 0px 0px;
    border-bottom: 1px dashed #ededed;
    grid-template-areas: "container1-bottom-left container2-bottom-right";
}
.main-half-container-bottom {
    grid-area: 2 / 2 / 3 / 4;
    display: grid;
    grid-template-columns: 1565px 1565px;
    grid-template-rows: 1002px;
    grid-auto-flow: row;
    gap: 0px 0px;
    border-bottom: 1px dashed #ededed;
    grid-template-areas: "container3-bottom-left container4-bottom-right";
}

/* -------- MAIN SIDEBAR : (230px x 2004px) -------- */
.sidebar-laplace {
    grid-area: 1 / 1 / 3 / 2;
    width: 100%;
    padding: 70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: url("../../assets/logo-laplace.svg");
    background-repeat: no-repeat;
    background-position: 0, center;
}
.sidebar-laplace * {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* -------- 4 SMALL CONTAINERS : (1565px x 1002px) -------- */
/* ------------------------ WITH ------------------------ */
/* ----- 2 EACH CHILD CONTAINER : (782,5px x 1002px) --- */
.container1-top-left {
    display: grid;
    grid-template-columns: 782.5px 782.5px;
    grid-template-rows: 1002px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "container1-child-left container1-child-right";
    grid-area: "container1-top-left";
}
.container2-top-right {
    display: grid;
    grid-template-columns: 782.5px 782.5px;
    grid-template-rows: 1002px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "container2-child-left container2-child-right";
    grid-area: "container2-top-right";
}
.container3-bottom-left {
    display: grid;
    grid-template-columns: 782.5px 782.5px;
    grid-template-rows: 1002px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "container3-child-left container3-child-right";
    grid-area: "container3-bottom-left";
}
.container4-bottom-right {
    display: grid;
    grid-template-columns: 782.5px 782.5px;
    grid-template-rows: 1002px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "container4-child-left container4-child-right";
    grid-area: "container4-bottom-right";
}
/* container one card style */
.container-child-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 100px 40px;
}
.container-child-style * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* ------- 1 CHILD CONTAINER DIVIDED IN 4 SUBSECTIONS FOR MULTICARDS ------- */
.container2-child-left {
    display: grid;
    grid-template-columns: 391.25px 391.25px;
    grid-template-rows: 501px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "container2-multicard-top-left container2-multicard-top-right"
        "container2-multicard-bottom-left container2-multicard-bottom-right";
    grid-area: "container2-child-left";
}

.container4-child-left {
    display: grid;
    grid-template-columns: 391.25px 391.25px;
    grid-template-rows: 501px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "container4-multicard-top-left container4-multicard-top-right"
        "container4-multicard-bottom-left container4-multicard-bottom-right";
    grid-area: "container4-child-left";
}

/* ---- Padding styled for small card ---- */
.padding-small-card-top {
    padding: 100px 40px 40px 40px;
}
.padding-small-card-bottom {
    padding: 40px 40px 100px 40px;
}

/* -------- BACKGROUND ANIMATION -------- */
.bg-animation {
    background-repeat: repeat-x;
    background-size: cover;
    background-position: top left;
    animation: animationBgLinechart 70s linear 0s infinite normal forwards;
}
@keyframes animationBgLinechart {
    100% {
        background-position-x: 50%;
    }
    0% {
        background-position-x: 100%;
    }
}

/* -------- BACKGROUND COLORS SMALL CARDS KPIS AND CRYPTO -------- */
.bg-color-small-card > div {
    /*   background-color: #06188bdd; */
    background: #231f20;
    background-color: #231f20;
    /*     background: #27359b;
    background-color: #27359b; */
    color: white;
}
.bg-color-small-card span:first-child {
    border-bottom: 1px solid rgba(160, 160, 160, 0.857);
}

/* For test only */
/* .main-container-laplace * {
    border: 1px solid red;
} */
