.modal_background {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 20; 
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.modal_shown {
  opacity: 1;
}
.modal_hidden {
  display: none;
  opacity: 0;
}
.edit-button-global {
  position: absolute;
  top: 29px;
  right: 35px;
  z-index: 11; 
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.5);
}
.edit-button-add-company {
  position: absolute;
  top: 29px;
  right: 105px;
}
.edit-button-gl-events-global {
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 30px;
}
.edit-button-gl-events-image {
  position: absolute;
}
.edit-button-gl-events-sidebar {
  margin: 15px;
}
.edit-button-blue {
  background-color: #06188b;
  border: 1px solid #06188b;
  color: white;
}
.edit-button-orange {
  background-color: #f8914d;
  border: 1px solid #f8914d;
  color: white;
}
.edit-button-white {
  background-color: white;
  border: 1px solid white;
  color: #06188b;
}

.modal_card {
  position: absolute;
  width: 350px;
  border-radius: 42px;
  z-index: 21;
  background-color: white !important;
  padding: 50px;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.2);
}
.modal_card * {
  width: 100%;
}
.modal_card * p {
  display: flex;
  align-items: flex-start;
}

.error-message {
  color: red;
  margin: 0px;
}

.title {
  padding-bottom: 10px;
  font-size: 23px;
  text-transform: uppercase;
  color: #06188b;
  border-bottom: 3px solid #fddc5a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subtitle {
  margin: 0px 0px 40px 0px;
  font-size: 18px;
  text-transform: uppercase;
  color: #ec833e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_button{
  border: none;
  color: #06188b;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: 35px;
  padding: 0px;
  margin: 0px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.submit_button {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  border: 0px;
  margin: 30px 0px 0px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: center;
  font-size: 15.5px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 8px;
  border-radius: 10px;
  color: #fff;
  background-color: #06188bed;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}
.submit_button:hover, .submit_button:active {
    box-shadow: inset 0 0 40px rgba(256, 256, 256, 0.3);
}

input {
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0px 0px 10px 0px;
  display: block;
  width: 100%;
  padding: 12px;
}

select {
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0px 0px 10px 0px;
  display: block;
  width: 100%;
  padding: 12px;
}

.label {
  font-size: 15px;
  margin: 0px;
  color: black
}

.text-bold {
  display:flex;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 10px;
}


