html, body {
    height: 100%;
    margin: 0;
}

/* -------- MAIN PAGE CONTAINER : (3360px x 1002px) -------- */
/* ------------------------- WITH ------------------------- */
/* ----------- MAIN SIDEBAR : (230px x 1002px) ----------- */
/* ------- 2 MAIN CONTAINERS : (1565px x 1002px) -------- */
.main-container-gle {
    display: grid;
    grid-template-columns: 230px 1565px 1565px;
    grid-template-rows: 1002px;
    grid-auto-flow: row;
    grid-template-areas: "sidebar container-content container-content";
    width: 3360px;
    height: 1002px;
}
.main-container-gle * div {
    overflow: hidden;
}

/* -------- MAIN SIDEBAR : (230px x 1002px) -------- */
.sidebar-gle {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    padding: 70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.sidebar-gle * {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* -------- MAIN CONTAINER CONTENT : (3130px x 1002px) -------- */
.container-content { 
  display: grid;
  grid-template-columns: 1565px 1565px;
  grid-template-rows: 1002px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "container-image1 container-image2";
  grid-area: container-content;
}
.container-image1 { 
  position: relative;
  grid-area: container-image1; }
.container-image2 { 
  position: relative;
  grid-area: container-image2; }

/* container style */
.container-style {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.container-style * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



  /* For test only */
  .main-container-gle *  {
    /* border: 1px solid red; */
  }


